import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import {
  isRouteErrorResponse,
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useMatches,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react";
import { withSentry } from "@sentry/remix";
import { useChangeLanguage } from "remix-i18next/react";

import * as Sentry from "@sentry/react";
import { authkitLoader } from "@workos-inc/authkit-remix";
import { FrownIcon } from "lucide-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "~/tailwind.css?url";
import { Impersonation } from "./components/impersonation";
import { Button } from "./components/ui/button";
import i18next from "./i18next.server";

import Hotjar from "@hotjar/browser";

const siteId = 5345894;
const hotjarVersion = 6;

export const loader = async (args: LoaderFunctionArgs) => {
  return authkitLoader(args, async () => {
    const locale = await i18next.getLocale(args.request);
    const cookie_bot = Boolean(process.env.COOKIE_BOT) || false;
    const env = {
      SENTRY_ENABLED: process.env.SENTRY_ENABLED === "true",
    };
    const origin = new URL(args.request.url).origin;
    return { locale, env, cookie_bot, origin };
  });
};

export const links: LinksFunction = () => [{ rel: "stylesheet", href: styles }];

export function Layout({ children }: { children: React.ReactNode }) {
  // Get the locale from the loader
  const data = useRouteLoaderData<typeof loader>("root");
  const lang = data ? data.locale : "en";
  const { pathname } = useLocation();
  const canonicalUrl = data
    ? data.origin + (pathname.endsWith("/") ? pathname.slice(0, -1) : pathname)
    : undefined;

  const SENTRY_ENABLED = data?.env.SENTRY_ENABLED || false;

  const { i18n } = useTranslation();
  const impersonator = data?.impersonator;

  // This hook will change the i18n instance language to the current locale
  // detected by the loader, this way, when we do something to change the
  // language, this locale will change and i18next will load the correct
  // translation files
  useChangeLanguage(lang);

  useEffect(() => {
    if (!SENTRY_ENABLED) return;
    console.log("Sentry.init");

    try {
      Sentry.init({
        dsn: "https://0846a56767fecf742ee1a26619c84abc@o4507893368291328.ingest.de.sentry.io/4507990655500368",
        tracesSampleRate: 1,

        beforeSend(event, hint) {
          // Check if it is an exception, and if so, show the report dialog
          if (event.exception && event.event_id) {
            Sentry.showReportDialog({ eventId: event.event_id });
          }
          return event;
        },

        integrations: [
          Sentry.feedbackIntegration({
            // Additional SDK configuration goes in here, for example:
            colorScheme: "system",
          }),
          Sentry.browserTracingIntegration({
            useEffect,
            useLocation,
            useMatches,
          }),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: true,
          }),
        ],

        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1,
      });

      if (data?.user) {
        Sentry.setUser({
          id: String(data.user.id),
          email: data.user.email,
          name: data.user.firstName + " " + data.user.lastName,
        });
      } else {
        Sentry.setUser(null);
      }
    } catch (error) {
      console.error("Sentry.init error", error);
    }
  }, [SENTRY_ENABLED]);

  useEffect(() => {
    Hotjar.init(siteId, hotjarVersion);
  }, []);

  return (
    <html lang={lang} dir={i18n.dir(lang)}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        <Links />
        <Meta />
        {data?.cookie_bot && (
          <script
            id="Cookiebot"
            src="https://consent.cookiebot.com/uc.js"
            data-cbid="71143f45-0fb2-4082-b1c4-e3944b5f3fff"
            data-blockingmode="auto"
            type="text/javascript"
          ></script>
        )}
      </head>
      <body>
        <Impersonation impersonator={impersonator} user={data?.user} />
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  const data = useRouteLoaderData<typeof loader>("root");
  const lang = data ? data.locale : "en";

  const { i18n } = useTranslation();
  let ErrorElement = (
    <>
      <h1 className="text-4xl font-bold text-red-600">Unknown Error</h1>
      <p className="text-lg text-gray-600">
        Oops! Something went wrong, and we couldn't process your request.
      </p>
      <p className="text-sm text-gray-500">
        Please try refreshing the page or contact support if the issue persists.
      </p>
    </>
  );

  if (isRouteErrorResponse(error)) {
    ErrorElement = (
      <>
        <h1 className="text-4xl font-bold text-red-600">
          {error.status} {error.statusText}
        </h1>
        <p className="text-lg text-gray-600">
          Sorry, we couldn't find what you were looking for.
        </p>
        <p className="text-sm text-gray-500 mb-6">
          This might be because the page doesn't exist, or there was an issue
          with your request.
        </p>
        <Button asChild variant="default">
          <Link to="/">Return to Home</Link>
        </Button>
      </>
    );
  } else if (error instanceof Error) {
    const eventId = Sentry.captureException(error);
    ErrorElement = (
      <>
        <h1 className="text-4xl font-bold text-red-600 text-center">
          {error.message}
        </h1>
        {error.stack && (
          <pre className="bg-gray-100 p-4 rounded-md border border-gray-300 overflow-auto text-sm text-left whitespace-pre-wrap">
            {error.stack}
          </pre>
        )}
        <p className="text-center text-gray-600">
          If you think this is a bug, please report it to our support team.
        </p>
        <Button
          variant="default"
          onClick={() => {
            Sentry.showReportDialog({ eventId });
          }}
        >
          Contact Support
        </Button>
      </>
    );
  }

  return (
    <html lang={lang} dir={i18n.dir(lang)}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-6">
        <div className="max-w-3xl w-full bg-white shadow-md rounded-lg p-8 space-y-8">
          <div className="text-center">
            <FrownIcon className="w-32 h-32 mx-auto" />
          </div>
          <div className="text-center space-y-4">{ErrorElement}</div>
        </div>
        <footer className="mt-8 text-center text-sm text-gray-500">
          &copy; {new Date().getFullYear()} MaharooLab. All rights reserved.
        </footer>
        <Scripts />
      </body>
    </html>
  );
};

function App() {
  return <Outlet />;
}

export default withSentry(App);
